<template>
	<div>
		<b-tabs vertical pills nav-class="nav-left" nav-wrapper-class="col-md-2 col-12" content-class="col-12 col-md-10 mt-1 mt-md-0">
			<b-tab title="كشف حضور وانصراف">
				<b-card no-body>
					<div class="m-2">
						<b-row>
							<b-col cols="12" md="2" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
								<label>Entries</label>
								<v-select
									v-model="perPage"
									:options="perPageOptions"
									:clearable="false"
									class="per-page-selector d-inline-block ml-50 mr-1"
								/>
							</b-col>

							<b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
								<label class="mr-50">From</label>
								<b-input-group>
									<b-form-input
										v-model="dateFrom"
										type="text"
										placeholder="YYYY-MM-DD"
										autocomplete="off"
										show-decade-nav
									/>
									<b-input-group-append>
										<b-form-datepicker
											v-model="dateFrom"
											show-decade-nav
											button-only
											right
											locale="en-US"
											@context="onContext"
										/>
									</b-input-group-append>
								</b-input-group>
							</b-col>

							<b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
								<label class="mr-50">To</label>
								<b-input-group>
									<b-form-input
										v-model="dateTo"
										type="text"
										placeholder="YYYY-MM-DD"
										autocomplete="off"
										show-decade-nav
									/>
									<b-input-group-append>
										<b-form-datepicker
											v-model="dateTo"
											show-decade-nav
											button-only
											right
											locale="en-US"
											@context="onContext"
										/>
									</b-input-group-append>
								</b-input-group>
							</b-col>

							<b-col cols="12" md="4">
								<div class="d-flex align-items-center justify-content-end">
									<b-form-input class="d-inline-block " placeholder="Search..." />
								</div>
							</b-col>
						</b-row>
					</div>
					<div dir="rtl" align="left" class="position-relative table-responsive pb-2">
						<table id="workersSummaryTable" class=" table b-table table-bordered">
							<thead>
								<tr role="row" class="">
									<th rowspan="2"><div>الاسم</div></th>
									<th rowspan="2"><div>النوع</div></th>
									<th colspan="2"><div>السيت</div></th>
									<th colspan="2"><div>الاحد</div></th>
									<th colspan="2"><div>الاثنين</div></th>
									<th colspan="2"><div>الثلاثاء</div></th>
									<th colspan="2"><div>الاربعاء</div></th>
									<th colspan="2"><div>الخميس</div></th>
									<th colspan="2"><div>الجمعة</div></th>
									<th rowspan="2"><div>عدد ايام العمل</div></th>
									<th rowspan="2"><div>ملاحظات</div></th>
								</tr>

								<tr role="row" class="">
									<th><div>ف1</div></th>
									<th><div>ف2</div></th>
									<th><div>ف1</div></th>
									<th><div>ف2</div></th>
									<th><div>ف1</div></th>
									<th><div>ف2</div></th>
									<th><div>ف1</div></th>
									<th><div>ف2</div></th>
									<th><div>ف1</div></th>
									<th><div>ف2</div></th>
									<th><div>ف1</div></th>
									<th><div>ف2</div></th>
									<th><div>ف1</div></th>
									<th><div>ف2</div></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in itemsThree" :key="item.id">
									<td>{{ item.name }}</td>
									<td>{{ item.skilled ? 'ماهر' : 'غير ماهر' }}</td>
									<td>
										<feather-icon v-if="item.p11" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p21" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p12" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p22" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p13" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p23" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p14" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p24" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p15" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p25" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p16" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p26" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p17" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>
										<feather-icon v-if="item.p27" icon="CheckIcon" size="18" style="color: green" />
										<span v-else>--</span>
									</td>
									<td>{{ item.ledger_code }}</td>
									<td>{{ item.note }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</b-card>
			</b-tab>
			<b-tab title="سجل مراقبة النقدية">
				<base-table :headers="headersOne" :items="itemsOne" table-top> </base-table>
			</b-tab>
			<b-tab title="سجل العهد الشخصية">
				<base-table :headers="headersTwo" :items="itemsTwo" table-top> </base-table>
			</b-tab>
		</b-tabs>
	</div>
</template>
<script>
import { BTabs, BCard, BCol, BInputGroup, BInputGroupAppend, BFormInput, BFormDatepicker, BRow, BTab } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';

import vSelect from 'vue-select';

export default {
	name: 'Reports',

	components: {
		BTabs,
		BCard,
		BCol,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		BFormDatepicker,
		BRow,
		BTab,
		BaseTable,
		vSelect,
	},

	data: () => ({
		headersOne: [
			{ key: 'date', label: 'التاريخ' },
			{ key: 'type', label: 'نوع مؤيد الصرف ورقمة' },
			{ key: 'sub_item', label: 'البند الفرعي' },
			{ key: 'main_item', label: 'البند الرئيسي' },
			{ key: 'ledger_code', label: 'القيد المحاسبي' },
			{ key: 'statement', label: 'البيان' },
			{ key: 'balance', label: 'الرصيد' },
			{ key: 'outcome', label: 'المنصرف' },
			{ key: 'income', label: 'الوارد' },
		],

		headersTwo: [
			{ key: 'note', label: 'ملاحظات' },
			{ key: 'code', label: 'القيد المخزني' },
			{ key: 'date', label: 'تاريخ الصرف' },
			{ key: 'ledger_code', label: 'القيد المحاسبي' },
			{ key: 'rest', label: 'الكمية المخلاه' },
			{ key: 'outcome', label: 'الكمية المصروفة' },
			{ key: 'details', label: 'المواصفات' },
		],

		headersThree: [
			{ key: 'note', label: 'ملاحظات' },
			{ key: 'ledger_code', label: 'عدد ايام العمل' },
			{ key: 'friday', label: 'الجمعة' },
			{ key: 'thursday', label: 'الخميس' },
			{ key: 'wensday', label: 'الاربعاء' },
			{ key: 'tusday', label: 'الثلاثاء' },
			{ key: 'monday', label: 'الاثنين' },
			{ key: 'sunday', label: 'الاحد' },
			{ key: 'saterday', label: 'السيت' },
			{ key: 'skilled', label: 'النوع' },
			{ key: 'name', label: 'الاسم' },
		],

		itemsThree: [
			{
				id: 1,
				note: '',
				ledger_code: 6,
				p11: true,
				p21: false,
				p12: true,
				p22: false,
				p13: true,
				p23: false,
				p14: true,
				p24: false,
				p15: true,
				p25: false,
				p16: true,
				p26: false,
				p17: false,
				p27: false,
				skilled: true,
				name: 'مجاهد مطيع سيف مرشد',
			},
			{
				id: 2,
				note: '',
				ledger_code: 5,
				p11: false,
				p21: false,
				p12: true,
				p22: false,
				p13: true,
				p23: false,
				p14: true,
				p24: false,
				p15: true,
				p25: false,
				p16: false,
				p26: true,
				p17: false,
				p27: false,
				skilled: false,
				name: 'قائد محمد صالح فتحي',
			},
			{
				id: 3,
				note: '',
				ledger_code: 6,
				p11: true,
				p21: false,
				p12: true,
				p22: false,
				p13: true,
				p23: false,
				p14: true,
				p24: false,
				p15: true,
				p25: false,
				p16: true,
				p26: false,
				p17: false,
				p27: false,
				skilled: false,
				name: 'وهيب احمد عثمان قاسم المنصوب',
			},
			{
				id: 4,
				note: '',
				ledger_code: 4,
				p11: false,
				p21: false,
				p12: true,
				p22: false,
				p13: true,
				p23: false,
				p14: true,
				p24: false,
				p15: true,
				p25: false,
				p16: false,
				p26: false,
				p17: false,
				p27: false,
				skilled: true,
				name: 'حزام سعد محسن الفقير',
			},
			{
				id: 5,
				note: '',
				ledger_code: 5,
				p11: false,
				p21: false,
				p12: true,
				p22: false,
				p13: true,
				p23: false,
				p14: true,
				p24: false,
				p15: true,
				p25: false,
				p16: false,
				p26: true,
				p17: false,
				p27: false,
				skilled: true,
				name: 'عصام احمد صالح السودي',
			},
			{
				id: 6,
				note: '',
				ledger_code: 6,
				p11: true,
				p21: false,
				p12: true,
				p22: false,
				p13: true,
				p23: false,
				p14: true,
				p24: false,
				p15: true,
				p25: false,
				p16: true,
				p26: false,
				p17: false,
				p27: false,
				skilled: false,
				name: 'جمال علي علي قيفه',
			},
			{
				id: 7,
				note: '',
				ledger_code: 4,
				p11: false,
				p21: false,
				p12: true,
				p22: false,
				p13: true,
				p23: false,
				p14: true,
				p24: false,
				p15: true,
				p25: false,
				p16: false,
				p26: false,
				p17: false,
				p27: false,
				skilled: true,
				name: 'يحيى علي عبدالله صالح القوقه',
			},
			{
				id: 8,
				note: '',
				ledger_code: 5,
				p11: false,
				p21: false,
				p12: true,
				p22: false,
				p13: true,
				p23: false,
				p14: true,
				p24: false,
				p15: true,
				p25: false,
				p16: false,
				p26: true,
				p17: false,
				p27: false,
				skilled: true,
				name: '	كمال شمسان محمد البلم',
			},
		],

		itemsOne: [
			{
				date: '20-06-2021',
				type: 56132,
				sub_item: 'أعمال ترميم الأبواب الخشبية ',
				main_item: 'أعمال النجارة التقليدية',
				ledger_code: 879465,
				statement: 'شراء خشب ',
				balance: 0,
				outcome: 150000,
				income: 150000,
			},
			{
				date: '11-06-2021',
				type: 56132,
				sub_item: 'اعمال الفلس والاعادة للمباني بالحجر النقمي مقلفع بطانة',
				main_item: 'أعمال الفلس و الإعادة للجدران الحجرية',
				ledger_code: 8764531,
				statement: 'شراء مواد طلاء',
				balance: 10000,
				outcome: 35000,
				income: 45000,
			},
			{
				date: '02-06-2021',
				type: 56132,
				sub_item: 'توريد وتركيب قمريات زجاج ابيض ( عادي) بابعاد 50سم * 60 سم - عدد',
				main_item: 'اعمال العقود والقمريات',
				ledger_code: 98451132,
				statement: 'شراء قمريات زجاجية',
				balance: 5000,
				outcome: 75000,
				income: 80000,
			},
			{
				date: '15-06-2021',
				type: 56132,
				sub_item: 'اعمال الفلس والاعادة للمباني بالحجر النقمي مقلفع بطانة',
				main_item: 'أعمال الفلس و الإعادة للجدران الحجرية',
				ledger_code: 89644132,
				statement: 'شراء مواد طلاء',
				balance: 10000,
				outcome: 35000,
				income: 45000,
			},
			{
				date: '13-06-2021',
				type: 56132,
				sub_item: 'التزريق لاستبدال الأخشاب المكسرة بأخشاب جديدة - عدد',
				main_item: 'أعمال الأسقف الخشبية التقليدية',
				ledger_code: 786485312,
				statement: 'شراء خشب اسقف',
				balance: 5000,
				outcome: 75000,
				income: 80000,
			},
			{
				date: '10-06-2021',
				type: 56132,
				sub_item: 'اعمال الفلس والاعادة للمباني بالحجر النقمي مقلفع بطانة',
				main_item: 'أعمال الفلس و الإعادة للجدران الحجرية',
				ledger_code: 465315312,
				statement: 'شراء مواد طلاء',
				balance: 10000,
				outcome: 55000,
				income: 65000,
			},
		],

		itemsTwo: [
			{
				note: 'الادوات متضررة بشكل كبير',
				code: 978645312,
				date: '12-06-2021',
				ledger_code: 84656312,
				rest: 3,
				outcome: 3,
				details: 'ادوات حفر',
			},
			{
				note: '',
				code: 513623,
				date: '11-06-2021',
				ledger_code: 94561523,
				rest: 0,
				outcome: 6,
				details: 'عربية يد لنقل المواد',
			},
			{
				note: 'نظارات زجاجية واقية عدد 2 تالفه',
				code: 87946136549,
				date: '13-06-2021',
				ledger_code: 123564845,
				rest: 10,
				outcome: 12,
				details: 'نظارات زجاجية واقية',
			},
			{
				note: '',
				code: 65132561328,
				date: '12-06-2021',
				ledger_code: 123564879,
				rest: 12,
				outcome: 12,
				details: ' قفازات لليد',
			},
		],

		perPage: 25,
		perPageOptions: [10, 25, 50, 100],
		dateTo: '',
		dateFrom: '',

		formatted: '',
		selected: '',
	}),

	methods: {
		onContext(ctx) {
			this.formatted = ctx.selectedFormatted;
			this.selected = ctx.selectedYMD;
		},
	},
};
</script>

<style lang="scss" scoped>
tr > th {
	background-color: #f3f2f7;
	border-bottom: 2px solid #ebe9f1;
}
</style>
